import constant from "@/common/constant";
import httpUtil from "@/common/httpUtil";
var currentSurveyPropertyList = []
var currentSurvey = {}
var currentSurveyOwnerUserId = ''
var currentSurveyDataId = ''
const propertyShow = {
    show: 1,
    hide: 2,
    ignore: 3
}
export default {
    findPropertyById(survey, propertyId) {
        if (!survey) {
            return undefined
        }
        return survey.properties.find(property=>property.id === propertyId)
    },
    //notReturnHidden 不返回hidden的数据
    findPropertyValueById(survey, propertyId,notReturnHidden) {
        const property = this.findPropertyById(survey, propertyId)
        return this.findValueFromProperty(property,notReturnHidden)
    },
    //notReturnHidden 不返回hidden的数据
    findValueFromProperty(property,notReturnHidden) {
        if (property) {
            if (!property.propertyData) {
                return ''
            }
            if (property.propertyData.hidden && notReturnHidden) {
                return ''
            }
            switch (property.type) {
                case constant.surveyPropertyType.singleSelect: {
                    let val = ''
                    if (property.selectDatasource) {
                        return property.propertyData.single;
                    }
                    if (property.options) {
                        const option = property.options.find(op => op.id === property.propertyData.single)
                        if (option) {
                            val = option.name
                            if (option.other && property.propertyData.content) {
                                val += '[' + property.propertyData.content + ']'
                            }
                        }
                    }
                    return val
                }
                case constant.surveyPropertyType.multiSelect: {
                    if (property.selectDatasource) {
                        return property.propertyData.multi;
                    }
                    let val = []
                    if (property.options && property.propertyData.multi) {
                        for (const option of property.options) {
                            if (property.propertyData.multi.includes(option.id)) {
                                if (option.other && property.propertyData.content) {
                                    val.push(option.name + '[' + property.propertyData.content + ']')
                                }else
                                    val.push(option.name)
                            }
                        }
                    }
                    return val.join(',')
                }
                case constant.surveyPropertyType.text:
                case constant.surveyPropertyType.textarea: {
                    return property.propertyData.content
                }
                case constant.surveyPropertyType.image: {
                    return property.propertyData.imgUrls
                }
                case constant.surveyPropertyType.file: {
                    return property.propertyData.fileUrls
                }
                case constant.surveyPropertyType.date: {
                    return this.formatSurveyValueDate(property);
                }
                default: {
                    return ''
                }
            }
        }
        return ''
    },
    formatSurveyValueDate(dateProperty) {
        if (dateProperty && dateProperty.propertyData && dateProperty.propertyData.content) {
            let content = dateProperty.propertyData.content
            switch (dateProperty.displayType) {
                case constant.surveyPropertyDateDisplayType.ymd: {
                    return content.substring(0, 10)
                }
                case constant.surveyPropertyDateDisplayType.ym: {
                    return content.substring(0, 7)
                }
                case constant.surveyPropertyDateDisplayType.y:{
                    return content.substring(0, 4)
                }
                default:{
                    return ''
                }
            }
        }
        return ''
    },
    /*
    targetProperty: 接收到消息的property
    * return 1 show
    *          2 hide
    *           3 ignore
    * */
    showProperty(targetProperty, sourceProperty) {
        //ignore self
        if (targetProperty.id === sourceProperty.id) {
            return propertyShow.ignore
        }
        // console.log(currentSurveyPropertyList)
        const relateProperties = targetProperty.relateProperties
        const relatePropertyAnd = targetProperty.relatePropertyAnd
        //ignore not relate
        if (!relateProperties) {
            return propertyShow.ignore
        }
        const relateP = relateProperties.find(p=>p.propertyId === sourceProperty.id)
        if (!relateP) {
            return propertyShow.ignore
        }
        if (relatePropertyAnd) { //and
            for (const relateProperty of relateProperties) {
                const currentProperty = currentSurveyPropertyList.find(currentProperty => currentProperty.id === relateProperty.propertyId)
                if (currentProperty) {
                    const shouldShow = this.shouldShow(relateProperty, currentProperty)
                    if (!shouldShow) { //hide
                        return propertyShow.hide
                    }
                }
            }
            return propertyShow.show
        }else { // or
            for (const relateProperty of relateProperties) {
                const currentProperty = currentSurveyPropertyList.find(currentProperty => currentProperty.id === relateProperty.propertyId)
                if (currentProperty) {
                    const shouldShow = this.shouldShow(relateProperty, currentProperty)
                    if (shouldShow) { //show
                        return propertyShow.show
                    }
                }
            }
            return propertyShow.hide
        }
    },
    //private
    /*
    * * return true show
    *          false hide
    * */
    shouldShow(relateProperty,sourceProperty) {
        let sourcePropertyValues = []
        const propertyData = sourceProperty.propertyData
        if (sourceProperty.type === constant.surveyPropertyType.singleSelect) {
            sourcePropertyValues.push(propertyData.single)
        }else if (sourceProperty.type === constant.surveyPropertyType.multiSelect) {
            Array.prototype.push.apply(sourcePropertyValues, propertyData.multi);
        }
        // console.log(relateProperty)
        // console.log(propertyValues)
        let show = false
        for (const propertyValue of sourcePropertyValues) {
            if ( relateProperty.optionIds.includes(propertyValue)){
                show = true
                break
            }
        }
        return show
    },
    setCurrentSurveyPropertyList(surveyPropertyList) {
        currentSurveyPropertyList = surveyPropertyList
    },
    findPropertyFromCurrentSurveyPropertyListById(propertyId) {
        return currentSurveyPropertyList.find(currentProperty => currentProperty.id === propertyId)
    },
    setCurrentSurvey(survey) {
        currentSurvey = survey
    },
    getCurrentSurvey() {
        return currentSurvey
    },
    setCurrentSurveyOwnerUserId(surveyOwnerUserId) {
        currentSurveyOwnerUserId = surveyOwnerUserId
    },
    getCurrentSurveyOwnerUserId() {
        return currentSurveyOwnerUserId
    },
    setCurrentSurveyDataId(surveyDataId) {
        currentSurveyDataId = surveyDataId
    },
    getCurrentSurveyDataId() {
        return currentSurveyDataId
    },
    doDataChangedAction(property, value) {
        console.log(value)
        console.log(property.dataChangedAction)
        if (value && property.dataChangedAction) {
            const params = {
                value: value,
                property: property,
                survey: this.getCurrentSurvey(),
                ownerUserId: this.getCurrentSurveyOwnerUserId(),
                surveyDataId: this.getCurrentSurveyDataId()
            }
            httpUtil.post(decodeURIComponent(property.dataChangedAction), params, res => {
                const resultData = res.data
                if (resultData.result && resultData.targetPropertyDataList) {
                    for (const targetPropertyData of resultData.targetPropertyDataList) {
                        const targetProperty = this.findPropertyFromCurrentSurveyPropertyListById(targetPropertyData.propertyId)
                        if (targetProperty) {
                            this.setPropertyDataValue(targetProperty, targetPropertyData)
                        }
                    }
                }
            })
        }
    },
    setPropertyDataValue(property, targetPropertyData) {
        if (property.type === constant.surveyPropertyType.singleSelect) {
            property.propertyData.single = targetPropertyData.single
        } else if (property.type === constant.surveyPropertyType.multiSelect) {
            property.propertyData.multi = targetPropertyData.multi
        } else if (property.type === constant.surveyPropertyType.text ||
            property.type === constant.surveyPropertyType.textarea) {
            property.propertyData.content = targetPropertyData.content
        } else if (property.type === constant.surveyPropertyType.image) {
            property.propertyData.imgUrls = targetPropertyData.imgUrls
        } else if (property.type === constant.surveyPropertyType.file) {
            property.propertyData.fileUrls = targetPropertyData.fileUrls
        } else if (property.type === constant.surveyPropertyType.date) {
            property.propertyData.content = targetPropertyData.content
        }
        property.propertyData.immutable = targetPropertyData.immutable
    }
}